import classNames from "classnames";
import { GetServerSideProps, GetServerSidePropsContext, NextPage } from "next";
import Image from "next/legacy/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";
import { Button } from "~/components/ui/Button";
import { useOnboardingDict } from "~/hooks/useTranslations";
import Logo from "~/public/images/logo-horizontal.svg";
import WelcomeBackground from "~/public/images/arena-new.png";
import { getLogInUrl, getSignUpUrl } from "~/utils/url";

const WelcomePage: NextPage = () => {
  const { t, tc } = useOnboardingDict();
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [returnTo, setReturnTo] = useState("");

  useEffect(() => {
    const { searchParams } = new URL(document.location.href);
    setReturnTo(searchParams.get("returnTo") ?? "");
  }, [setReturnTo]);

  useTimeoutFn(() => {
    setIsPanelVisible(true);
  }, 1000);

  const clxBg = classNames(
    "relative",
    "w-full",
    "h-full",
    "animate-in",
    "fade-in",
    "duration-700",
    "transition-transform",
    {
      "-translate-y-32": isPanelVisible,
    },
  );

  const clxLoginPanel = classNames(
    "duration-700",
    "transition-transform",
    "absolute",
    "z-10",
    "bottom-[-359px]",
    "w-full",
    "h-[359px]",
    "mt-auto",
    "p-4",
    "pt-9",
    "pb-14",
    "bg-white",
    "rounded-t-3xl",
    "flex",
    "flex-col",
    "justify-center",
    "items-center",
    {
      "-translate-y-[359px]": isPanelVisible,
    },
  );

  return (
    <>
      <div className={clxBg}>
        <Image
          src={WelcomeBackground}
          layout="fill"
          objectFit="cover"
          alt="Background image"
          priority
        />
      </div>

      <div className={clxLoginPanel}>
        <div style={{ width: 143, height: 35 }}>
          <Logo />
        </div>

        <p className="mb-8 mt-4 px-8 text-body-3">{t("fpWelcomeTitle")}</p>

        <Link href={getSignUpUrl()}>
          <Button
            data-testid="signup-button"
            size="lg"
            className="w-full flex-auto"
          >
            {t("fpSignUp")}
          </Button>
        </Link>

        <p className="mt-4 text-center text-caption-1">
          {t("fpAlreadyHaveAnAccount")}{" "}
          <Link href={getLogInUrl(returnTo)}>
            <Button
              variant="ghost"
              className="flex-auto"
              data-testid="login-button"
            >
              {tc("fpLogIn")}
            </Button>
          </Link>
        </p>
      </div>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const pageProps = { props: { messages: {} } };
  pageProps.props.messages = {
    ...(await import(`~/messages/en.json`)).default,
  };
  return pageProps;
};

export default WelcomePage;
